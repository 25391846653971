
import { Vue, Component, Prop } from "vue-property-decorator";
import { mdiCheckCircle, mdiInformation } from "@mdi/js";

@Component
export default class AlertBox extends Vue {
    @Prop({ required: false, default: null }) icon!: string | null;
    @Prop({ required: false, default: "info" }) color!: string;
    @Prop({ required: false, default: false, type: Boolean }) noIcon!: boolean;

    infoIcon: string = mdiInformation;
    successIcon: string = mdiCheckCircle;

    get colorIcon(): string | null {
        if (this.icon) {
            return this.icon;
        }
        if (this.color === "info") {
            return this.infoIcon;
        }

        if (this.color === "success") {
            return this.successIcon;
        }
        return null;
    }
}
