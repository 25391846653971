
import { Component, Vue } from "vue-property-decorator";
import OrderApi from "@/services/api/order";
import { AxiosError, AxiosResponse } from "axios";
import Order from "@/models/order";
import { mdiDownload } from "@mdi/js";
import OrderReceipt from "@/components/OrderReceipt.vue";
import Pusher from "@/services/websockets/pusher";
import capitalize from "@/filters/capitalize";
import { Channel } from "pusher-js";
import { Getter } from "vuex-class";
import { addAnalyticsEvent } from "@/plugins/firebase";
import BackButton from "@/components/BackButton.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBox from "@/components/AlertBox.vue";
import CompositeAlert from "@/components/CompositeAlert.vue";
import SnackAlert from "@/components/SnackAlert.vue";
import Payment from "@/services/api/payment";
import { ApiResponse } from "@/services/api/axios";

@Component({
    components: {
        SnackAlert,
        CompositeAlert,
        AlertBox,
        LoadingSpinner,
        BackButton,
        OrderReceipt,
    },
})
export default class OrdersShow extends Vue {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    order: Order = null!;
    downloadIcon: string = mdiDownload;
    channel: null | Channel = null;
    paymentStatus: string | null = null;
    @Getter("authToken") authToken!: string;

    mounted() {
        if (typeof this.$route.query["status"] == "string") {
            this.paymentStatus = this.$route.query["status"];
        }

        if (!this.$route.params.orderId) {
            addAnalyticsEvent("invalid_order_id");
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.ERROR,
                "We ran into an error while fetching the order."
            );
            this.$router.push({
                name: this.$constants.ROUTE_NAMES.ORDERS_INDEX,
            });
            return;
        }

        OrderApi.get(this.$route.params.orderId)
            .then((response: AxiosResponse) => {
                this.order = new Order(response.data.data);
                if (this.paymentStatus === "cancelled") {
                    if (this.order?.isInProgress) {
                        Payment.cancel(this.order.orderId)
                            .then(() => {
                                this.$root.$emit(
                                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                                    "The payment has been cancelled successfully"
                                );
                            })
                            .catch(this.handleAxiosError);
                    }
                    this.listenForOrderUpdates(this.order);
                    return;
                }

                if (
                    this.order.requiresPaymentRedirect &&
                    this.paymentStatus === null
                ) {
                    setTimeout(() => {
                        window.location.href = this.order
                            ?.paymentProviderCheckoutUrl as string;
                    }, 1000);
                    return;
                }

                if (this.order.isInProgress) {
                    this.listenForOrderUpdates(this.order);
                }

                this.showConfetti();
            })
            .catch(this.handleAxiosError);
    }

    showConfetti() {
        if (this.order?.isAwaitingPayment && this.order.isReferred) {
            this.$confetti.start({
                particles: [
                    {
                        type: "heart",
                    },
                ],
            });
            setTimeout(() => {
                this.$confetti.stop();
            }, 4000);
        }
    }

    loadOrder() {
        OrderApi.get(this.$route.params.orderId)
            .then((response: AxiosResponse) => {
                this.order = new Order(response.data.data);
            })
            .catch(this.handleAxiosError);
    }

    beforeDestroy() {
        if (this.channel) {
            this.channel.unbind_all();
        }
    }

    downloadPDFClicked() {
        addAnalyticsEvent("download_pdf_button_clicked");
    }

    listenForOrderUpdates(order: Order) {
        this.channel = Pusher.subscribe("order-status");
        this.channel.bind(order.orderId, (data: { message: string }) => {
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                capitalize(data.message)
            );

            this.loadOrder();

            if (data.message.toUpperCase().trim() === "SUCCESSFUL") {
                addAnalyticsEvent("purchase", {
                    currency: "XAF",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    transaction_id: order.orderId,
                    value: order.paymentAmount,
                    coupon: order.referralCode,
                    items: [
                        {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_id: order.itemId,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_name: order.itemName,
                            coupon: order.referralCode,
                        },
                    ],
                });
            }
        });

        addAnalyticsEvent("listening_for_order_updates", {
            orderId: order.orderId,
        });
    }
    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.$router.push({ name: this.$constants.ROUTE_NAMES.HOME });
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.response?.data?.message ?? error.message
        );
    }
}
